<cdk-virtual-scroll-viewport class="detailViewport" itemSize="10">
  <table mat-table [dataSource]="files" class="mat-elevation-z8">
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="9999">
        Geen documenten gevonden.
      </td>
    </tr>

    <ng-container matColumnDef="fileName">
      <th mat-header-cell *matHeaderCellDef class="borderHeader"> Naam</th>
      <td mat-cell *matCellDef="let element">
        {{element.name}}
      </td>
    </ng-container>

    <ng-container matColumnDef="fileType">
      <th mat-header-cell *matHeaderCellDef class="borderHeader"> Type</th>
      <td mat-cell *matCellDef="let element" (click)="downloadAndViewFile(element.id)"> {{enumDisplayHelper(remoteDocTypeDropdown, element.type)}} </td>
    </ng-container>

    <ng-container matColumnDef="uploadDate">
      <th mat-header-cell *matHeaderCellDef class="borderHeader"> Upload datum</th>
      <td mat-cell
          *matCellDef="let element"
          (click)="downloadAndViewFile(element.id)"> {{element.createdAt | date: 'dd-MM-yyyy'}} </td>
    </ng-container>

    <ng-container matColumnDef="menu">
      <th mat-header-cell *matHeaderCellDef class="borderHeader"></th>
      <td mat-cell *matCellDef="let element">
        <button mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon class="tableMenu">more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="downloadAndViewFile(element.id)">
            <mat-icon>remove_red_eye</mat-icon>
            <span>Bekijken</span>
          </button>
          <button mat-menu-item (click)="deleteFile(element.id)">
            <mat-icon>delete</mat-icon>
            <span>Verwijderen</span>
          </button>
<!--          <button mat-menu-item (click)="openRemoteMap(element)">
            <mat-icon>add_to_drive</mat-icon>
            <span>Bekijk in Google Drive</span>
          </button>-->
        </mat-menu>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row class="hoverableRow" *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</cdk-virtual-scroll-viewport>

<div class="card-footer" *ngIf="entity">
  <button class="uploadButton toGDrive" mat-button (click)="openRemoteMap(null)">
    Naar OneDrive
  </button>
  <!--    (click)="reloadFunction.emit(tab)"-->
  <button class="uploadButton" (click)="uploadDocument()" mat-button>
    Nieuw document uploaden
  </button>
</div>
