<mat-card>
  <mat-card-header>
    <mat-card-title>Opdracht beoordelen</mat-card-title>
    <button mat-button mat-dialog-close class="close-button" align="end">
      <mat-icon>clear</mat-icon>
    </button>
  </mat-card-header>

  <mat-card-content>
    <div class="dialog-content-container" *ngIf="submission">
      <app-file-list
        (filesChanged)="getSubmission()"
        [files]="submission.attachments"
      ></app-file-list>

      <!--      <div class="buttonIndialogBundler">-->
      <form [formGroup]="resultsFormGroup">
        <div class="inputBundler mixInputs">
          <mat-form-field appearance="fill">
            <mat-label>Beoordeling</mat-label>
            <input matInput formControlName="result">
          </mat-form-field>

          <mat-form-field appearance="fill" class="bigInput">
            <mat-label>Toelichting</mat-label>
            <textarea matInput formControlName="remarks"
                      cdkTextareaAutosize
                      #autosize="cdkTextareaAutosize"
                      cdkAutosizeMinRows="1"
                      cdkAutosizeMaxRows="3"></textarea>
          </mat-form-field>
        </div>
      </form>
      <!--      </div>-->
    </div>
  </mat-card-content>
  <mat-card-actions align="end">
    <button mat-button (click)="close()">Annuleren</button>
    <button mat-button [disabled]="resultsFormGroup.invalid" (click)="save()" class="saveButton">Opslaan</button>
  </mat-card-actions>
</mat-card>

